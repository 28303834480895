@import 'global';



.sticky-top.navbar-light{
    // background:#D980FA;
    // background:#e5eaf5 ;
    // background: url(../images/wave.png) top left no-repeat;
    background-size:cover;
    //  padding-bottom:1.5rem; 
    transition: 0.7s;
    // padding-bottom:1rem;
    // top:6rem;
    @include bp(lg) {  
        padding-bottom:0rem;
    }
    @include bp(md){
        background:none;
    }
    
}

.MuiTooltip-popper {
    margin-top: -7px;
}

.NavBox{
    background:none;z-index:9999;padding-top:1.4rem !important;
    transition: 0.7s;
      
    
        button {
        // box-shadow: 0 .125rem .25rem rgba(0,0,0,.075); 
        padding-left:0rem;
       }

  
.NavbarBox { 
    @include bp(md) {  
        // margin-top:1rem;
    }
   
    .nav-item{
        // background:$white;
        border-radius:12px;
        margin-left: 4.5px;
        margin-right: 4.5px;
       
          .dropdown-menu{
              border-radius:15px;padding:0rem;
            .dropdown-divider{margin:0rem;}
            span{
                &:last-child{
                .dropdown-divider{border:none;}
                }
                &:hover{
                    .nav-link{
                        background:$light-blue;
                        color:$white;
                    }
                   

                   &:nth-child(1){
                    .nav-link{
                        border-radius:15px 15px 0px 0px;
                    }
                     
                   }
                   &:last-child{
                    .nav-link{
                      border-radius:0px 0px 15px 15px;
                    }
                   }
                }
            }
            .nav-link{color:#363636;cursor:pointer;font-size:0.875rem;font-weight:600;
                @include bp(md) {  
                    padding-left:20px;
                }
            //   &:hover{
            //       background:$light-blue;
            //       color:$white;
            //      &:nth-child(1){
            //         border-radius:15px 15px 0px 0px;
            //      }
            //      &:last-child{
            //         border-radius:0px 0px 15px 15px;
            //      }
            //   }
            }
        }
    
        @include bp(md) {  
            margin-bottom:0.3rem;
        }
        .nav-link.linkBox{  
            background:$white;
            color:$blue;font-family:'gotham rounded medium';padding-bottom:0rem;font-size:1rem;  border-radius:12px;
           padding:10px 20px;  transition: 0.3s; box-shadow: 0px 0px 15px rgba(0,0,0,0.10);cursor:pointer;line-height:1;
           &:hover{
            background:$light-blue;
            color:$white;
             span{color:$white;}
        }

           &.active_class{background:$light-blue; 
             color:$white;
             span{color:$white;}
        } 
           span{
               color:#363636;font-family:$font-family;font-size:0.6875rem;
           }
        }
       
    }
}




@include bp(md) {  
    position:fixed;top:0rem;width:100%;padding:0rem;
    .navbar-toggler{right:7rem;top:30px;background:$white;border:none;position:fixed;z-index:9999;
    font-size:0.975rem;transform:translateX(-100);}
    .navbar-collapse{
        transform: translateX(-100%);
        transition: transform 0.5s linear;
        top:4.5rem;  
        left:0rem;
        height:100%;
        bottom:0rem;
        position:fixed;
        padding:1rem;
        width:100%;  
        &.collapsing{
            transform: translateX(-100%) !important;
            transition: transform 0.5s linear !important;
            top:4.5rem;
            left:0rem;
            bottom:0rem;
            height:100%;
            padding:1rem;
            background:#11134e87;
            position:fixed;
            width:100%;
        }
        //  &.collapse:not(.show){
        //    transform: translateX(-100%) !important;
        //    transition: transform 0.5s ease-in-out !important;
        //    top:4rem;
        //    display:block;
        //  }
    }
    .navbar-collapse.show{
        transform:translateX(0);
        width:100%;
        // background:$black;
        background:#11134e87;
        padding:1rem;
        position:fixed;
        height:100%;
        left:0rem;
        top:4.5rem;
        bottom:0rem;
        overflow-y:auto;
        
//   transition: transform 0.3s ease-in-out;

    }
}


@include bp(md) {
    .navbar-toggler{
        right: inherit;
        // top: 16px;
        left:1rem;
    }
    
}

@include bp(sm) {
    .navbar-toggler{
        right: inherit;
         top: 1rem;
        left:1rem;
    }
    
}



}

.mobileNav{
.navbar{
    @include bp(md) {
        padding:0rem;
       
    }
}
}

.mainAll{
    .navbar{
        @include bp(md){
            padding:0rem;
            position:fixed;
            top:0rem;
            transition:0.1s ease-in-out;
        }
    }
    }