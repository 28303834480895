@import 'global';



.BannerBox.discussionBanner{
    .sixteen-nine:before {  
        //background:url(../images/discussionboard.jpg) center center no-repeat;
        background:url(../images/govt-circular-banner.jpg) center center no-repeat;
        padding-top: 39.25%;
        //padding-top: 23%;
        background-size:cover;  
      }
}

.BannerBox.discussionBanner.blogs{
    .sixteen-nine:before {  
        background:url(../images/blogs.jpg) center center no-repeat;
        padding-top: 39.25%;
        //padding-top: 23%;
        background-size:cover;  
      }
}



.discussionMain{
      .appBox{
          border-bottom:2px solid #DFE3E6;
          .MuiTabs-flexContainer{
             @include bp(md){
                overflow-x:scroll;
             }
          }
          
          .MuiTab-textColorInherit.Mui-selected{
              position:relative; 
              display: inline-block;
     
              &::before{
                  content:"";
                  position:absolute;bottom:0rem;width:100%;
                  border-bottom:1px solid $blue;
              }
          }  
          .MuiTab-textColorInherit {
           
            padding: 0rem 0rem 1rem;
            margin: 0.5rem 1rem 0rem;
        }
          .customField{min-width:10rem;
             @include bp(sm){
                 min-width:100%;
                 margin-bottom:1rem;
             }
           }
        .MuiFilledInput-underline{
            &::before{ display:none;}
            &::after{ display:none;}  
        }
        .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]{
            padding-top:10px;
        }
        .MuiFormLabel-root{
            line-height: 0.5;
        }
        .MuiTab-root{
            min-width:auto;
        }
        .MuiFilledInput-root{
            border-radius:0px;
        }
      }

      .simpleTable{

        .MuiTableHead-root{
            .MuiTableRow-root{

        .MuiTableCell-head{   
            font-weight:bold;   
            &:nth-last-child(1){
               font-weight:normal;
            }
            &:nth-last-child(2){
                font-weight:normal;
             }
        }
        
    }
}

           .MuiTableBody-root{
            .MuiTableRow-root{
                &:nth-of-type(odd){
                    background:#F0F1F3;   
                }
               
                 .MuiTableCell-root{
                     h5{font-size:1rem;font-family:$font-family;}
                     p{margin-bottom:0rem;}
                     white-space: nowrap;
                     .list-inline-item{
                         background:#DF788C;border-radius:100%; width:30px;height:30px;line-height: 30px;text-align:center;
                         color:$white;text-transform:uppercase;  
                         &:nth-child(2n){
                            background:#BEB6C1; 
                         }
                         &:nth-child(3n){
                            background:#2A706B; 
                         }
                        
                     }

                     &:nth-child(1){
                        white-space: normal;
                        min-width:20rem;   
                           .badge{
                            margin-top:1rem;
                            color:$white;margin-right:0.5rem;padding:0.3rem 0.5rem;
                        
                            &:nth-child(2n){
                                background:#3F6A9F;
                            }
                            &:nth-child(3n){
                                background:#3130E1;
                            }
                            &:nth-child(4n){
                                background:#3DA8CC;
                            }
                           } 
                    }


                     &:nth-child(2){
                           position:relative;padding-left:2rem;
                         &::before{
                            content:"";
                            position:absolute;
                            margin-top:5px;left:1rem;
                            width:8px;height:8px;background:#317E77;border-radius:100%;display:inline-block; 

                         }
                     }
                     &:nth-child(4){
                         font-weight:bold;
                     }
                     
                 }

                 &:nth-child(2n){
                 
                    .MuiTableCell-root{
                        .list-inline-item{
                            &:nth-child(1){
                                background:#F3AE50; 
                             }
                            &:nth-child(2n){
                               background:#9BC9A5; 
                            }
                            &:nth-child(3n){
                               background:#5866A8; 
                            }
                           
                        }

                        &:nth-child(1){
                            .badge{
                             &:nth-child(2n){
                                 background:#E74386;
                             }
                             &:nth-child(3n){
                                 background:#6ABC62;
                             }
                             &:nth-child(4n){
                                 background:#3DA8CC;
                             }
                            } 
                     }
                     


                        &:nth-child(2){
                            
                          &::before{
                            
                             background:#352BF6;
 
                          }
                      }

                    }

                    
                 }

                 &:nth-child(3n){
                 
                    .MuiTableCell-root{
                        .list-inline-item{
                            &:nth-child(1){
                                background:#CA9CC2; 
                             }
                            &:nth-child(2n){
                               background:#391737; 
                            }
                            &:nth-child(3n){
                               background:#64C7D6; 
                            }
                           
                        }

                        &:nth-child(1){
                            .badge{
                             &:nth-child(2n){
                                 background:#7F217E;
                             }
                             &:nth-child(3n){
                                 background:#5C6B8B;
                             }
                             &:nth-child(4n){
                                 background:#92CB89;
                             }
                            } 
                     }

                        &:nth-child(2){
                            
                            &::before{
                              
                               background:#B8A57B;
   
                            }
                        }

                        
                    }

                    
                 }
                 &:nth-child(4n){
                 
                    .MuiTableCell-root{
                        .list-inline-item{
                            &:nth-child(1){
                                background:#CE2A49; 
                             }
                            &:nth-child(2n){
                               background:#CB9CC1; 
                            }
                            &:nth-child(3n){
                               background:#8E4939; 
                            }
                           
                        }

                        &:nth-child(1){
                            .badge{
                             &:nth-child(2n){
                                 background:#746BAE;
                             }
                             &:nth-child(3n){
                                 background:#EB9B27;
                             }
                             &:nth-child(4n){
                                 background:#2A7796;
                             }
                            } 
                     }

                        &:nth-child(2){
                            
                            &::before{
                              
                               background:#6F1730;
   
                            }
                        }

                    }
                    
                 }

               }
            }
       
      }
}

.threadBox{
    
    h3{font-family:$font-family;}  
     .replyIcon{
        transform: rotate(20deg);
       }
       .shareBox{
           border-radius:0rem; margin-right:1rem;margin-top:2rem;background:#F4AF50;
           text-transform:capitalize;box-shadow:none;
           &:nth-child(1){
               background:#E3E7EA;
           }
       }
    .category{
        position:relative;margin-right:2rem;padding-left:1rem;
        &::before{
            content:"";
            position:absolute;
            top:5px;left:0rem;
            width:8px;height:8px;background:#317E77;border-radius:100%;display:inline-block; 

         }
    }
.badge{
    margin-top:1rem;
    color:$white;margin-right:0.5rem;padding:0.3rem 0.5rem;

    &:nth-child(1n){
        background:#3F6A9F;
    }
    &:nth-child(2n){
        background:#3130E1;
    }
    &:nth-child(3n){
        background:#3DA8CC;
    }
   } 

   .usersBox{
    background: #2D706C;
    border-radius: 100%;
    color: #fff;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    margin-right: 2rem;
    font-weight: bold;

   }
   .media{
       background:#FCFCFC;
       padding:2rem;
       @include bp(md){
           padding:1rem;
       }
       h5{margin-top:0.5rem; font-size:1.0625rem;}
       h6{margin-top:0.5rem;}

      
       &.innerBox{
           border-left:2px solid #E1E5E6;background:#F6F7F9;
       }
       .headBox{margin-bottom:2rem;
          .iconReply{-webkit-transform: scaleX(-1);
            transform: scaleX(-1);}
        }
       .blue{
        background:#6B61FB;
        }
        .darkGreen{
            background:#234E5E;
        }
        .green{
            background:#47B48D;
        }
        .darkBlue{
            background:#5D6AA9;
        }
       .iconBox{
        .MuiSvgIcon-root{
            font-size:1rem;margin-top:-5px;margin-right:0.5rem;
        }
       }

       .likeIcon{
           color:#6E7074;  
           &:nth-child(2){
            .MuiSvgIcon-root{
                transform: rotate(90deg);
            }
           
           }
       }
       .iconHead{
         .usersBox{
             padding:1rem;
         }
       }

       .rightBox{
        .likeIcon{
            color:#6E7074;  
            &:nth-child(1){
                .MuiSvgIcon-root{
                    transform: rotate(20deg);
                }
               
               }

            &:nth-child(2){
             .MuiSvgIcon-root{
                 transform: rotate(0deg);
             }
            
            }
        }
       }

       .media-body{
          @include bp (sm){
            flex:0 0 100%;
          }
       }
      
   }   

}

.createThread{
    margin-top:5rem;
    @include bp (md){
        margin-top:2rem;
      }
    .MuiButton-containedSecondary{
        background:#f26522;
        border-radius:0rem;
        box-shadow:none;
        &:hover{
            background:#f26522;
        }
    }
    .MuiButton-containedPrimary{
        background:$blue;
        border-radius:0rem;
        box-shadow:none;
    }
   .card{
    border: 2px solid #F6F6F6;
    border-radius:0rem;
       .card-body{
           background:#FCFCFC;padding:1.25rem 2rem;
           @include bp (md){
           padding:0rem;
          }
           .appBox{
               border:none;
               .tox-tinymce{
                border: 1px solid #EFF0F3;
               }

               .tox .tox-statusbar{
                border-top: 1px solid #EFF0F3;
               }
               .tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary{
                   background:none;
                   border-bottom: 1px solid #F6F6F6;
               }
               .tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type){
                   border-right:1px solid #F6F6F6;
               }
               .customField1{
                .MuiFilledInput-input{padding-top:19px;}
               }
               .MuiFilledInput-root{
                   background:#F6F7F9;
                   border: 1px solid #EFF0F3;
                //    &:hover{
                //        background:#FCFCFC;
                //    }
               }
                  
               .tox .tox-edit-area__iframe{
                   background:#F6F7F9;
               }
           }
       }
   }  
}