@import 'global';  

.MuiAppBar-colorPrimary.customBar{

    @include bp(sm){
        .logoBox{width:40%;}
    }
    
    background:none;    
    box-shadow:none; 
    .MuiTypography-h3{text-transform:uppercase;color:$white; font-family: 'gotham rounded medium';font-size:2.5rem;margin-left:6rem;
        @include bp(md) {  
            display:block;
            margin-left:0rem;
            font-size:1.5rem;
               
         }
    }
    .MuiToolbar-regular{margin:1rem 0rem 1rem 0rem;
        @include bp(sm) {  
           margin-top:0rem;
           margin-bottom:0rem;
               
         }
    }
    .MuiButton-root{color:$blue;background:$white;border-radius:2rem;margin-left:1rem;padding:0rem 1.5rem;font-weight:600;font-family:'gotham rounded medium';
    height:40px; box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
     
    }
     .notificationBox{
        background: white;
        border-radius: 100%;
        margin-left: 1rem;
        width: 40px;
        height: 40px;
        color: $blue;
        line-height: 30px;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
        .MuiIconButton-root{padding:8px;}
        // .MuiBadge-colorSecondary{background:$blue;}
     }
    .callBox{
        background:$blue;
    color:$white;
    border-radius: 100%;
    padding: 5px;font-size:1.5rem;
    }
}

.MuiListItem-root.customMobileMenu{
    .MuiButton-root{color:$blue;background:$white;border-radius:2rem;margin-left:1rem;padding:0rem 1.5rem;font-weight:600;font-family:'gotham rounded medium';
        height:40px; box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12); 
        }
         .notificationBox{
            background: white;
            border-radius: 100%;
            margin-left: 1rem;
            width: 40px;
            height: 40px;
            color: $blue;
            line-height: 30px;
            box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
            .MuiIconButton-root{padding:8px;}
            // .MuiBadge-colorSecondary{background:$blue;}
         }
}
#primary-search-account-menu-mobile {
    .MuiPaper-root{
        background:rgba(255, 255, 255, 0.8);
    }
}

.WelcomeBox{
    // position:relative;background:#f4e6f7;min-height:100vh;
    position:relative; 
    // margin-bottom:-2rem;
    // padding-bottom:8rem;
    padding-bottom:10rem;

    @include bp(md){
        background:#f4e6f7;
        padding-bottom:0rem;
        margin-bottom:2rem;
    }
    &::before{  
       content:"";
       background:#f4e6f7 url("../images/teachertraining.png")  no-repeat;     
       width:100%;position:absolute;left:0rem;
        // top:-5rem;
    //    height:829px;
    
       height:100%;
       z-index:0;
      background-size:cover;
    //    background-size: 100%;    
       background-position: 50% 50%;

       @include bp(md){
            top:0rem;
       }
   }
   &::after{  
        content: "";
    position: absolute;
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    background:$light-blue;
    top: 34%;
    left: 35%;
    z-index: 3;
    

   }

   @include bp(md) { 
       min-height:100%;  
    &::before{  
        // height:630px;
        height:auto;
        background:#f4e6f7;
    }
    &::after{  
        left:57%;top:0%;
        width:4rem;height:4rem;
        display:none;
    }

}
.TeacherBox{
    padding-top: 18rem;
    padding-left: 8rem;padding-bottom:12rem;position:relative;
    h2{color:$h2color;font-size:3rem; }
    h3{color:$blue;font-family:'gotham rounded medium'; font-size:2.5rem;font-weight:600;}
    h4{color:#191919;font-size:1.125rem;font-weight:600;}

   

    .SignupBox{background:none;color:$blue;border:1px solid $blue;box-shadow:none;border-radius:25px;padding:0.5rem 1.5rem;margin-top:1rem;
     &:hover{
         background:$blue;color:$white;
     }
    }

    


    &::after{
        content: "";
    position: absolute;
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    background:$light-blue;
    top: 6rem;
    left: 0rem;
    z-index: 3;
    }


    @include bp(md) { 

       
        
        padding-left:1rem;
        padding-top:2rem;
        padding-bottom:1rem; 
        flex: 0 0 100%;
        max-width: 100%;
        h2{color:$blue;font-size:2.5rem;}
        h3{color:$blue;font-size:1.75rem;}
        h4{font-size:1.125rem;}  
        &::after{
            display:none;
        }
        
        .SignupBox{background:$blue;color:$white;box-shadow:none;
            &:hover{
                background:$white;color:$blue;
            }
           }

    }
    
    @include bp(sm) { 
        padding-bottom:2.5rem; 
    }


}
}


.StudentBox{   
    position:relative;
    // margin-top:5rem;
    // padding-bottom: 12rem;  

    .card-title{
        font-family:'gotham rounded medium';font-size:1.5rem;
        @include bp(md){
            font-size: 1.2rem;
        }
        
    }
    .card-body{
        padding:1.25rem 0rem;
    }
}

.MainBlogbox{
    position:relative;  
    &::before{  
        content:"";
        background:url("../images/recent-blog.png") top left no-repeat;     
        width:100%;position:absolute;left:0rem;top:-15rem; height:1372px;z-index:-1;  

        display:none;
    }

    @include bp(md) { 
        &::before{  
                top:-6rem;
        }
    }
}
.Blogbox{
    margin-top:4rem;  
    
    h2{font-family:$font-family;color:$blue;font-size:$head-size;}
    .card{margin-top:2rem; border-radius: 8px;
        box-shadow: 0px 0px 45px rgba(0,0,0,0.15);border:none;height:100%;  
        
        .card-body{flex:inherit;padding-bottom:0rem;}
    img{
        border-radius:8px 8px 0px 0px;
    }
    .card-title{font-family:'gotham rounded medium'}
    }

    @include bp(md){
        h2{
        font-size:$head-md;
        }
        .card{height:auto;}
      }

    @include bp(sm){
        margin-top:2.5rem;
        h2{
            // color:$white;
            color:$blue;  
            font-size:$head-small;}
    }
}
// .Blogbox.home-blog{
//     .card{
//         height:100%; 
//         @include bp(md){
//             .card{height:auto;}
//           }
//     }
// }


.SpeakerBox{
    margin-top:6rem;
    h2{font-family:$font-family;color:$white;font-size:$head-size;
        @include bp(md){
            font-size:$head-md;
          }

        @include bp(sm){
            font-size:$head-small;
           
        }
    }
    @include bp(md){
        margin-top:2.5rem;
        h2{color:$blue;text-align:center;}
    }
    
    @include bp(sm){
        h2{color:$white;}
    }
    .Speaker{margin-top:2rem;
         .speakImgBox{
             width:7rem;height:7rem;
              img{width:100%;height:100%;}
         }
        &:nth-child(4){
            
            h4{color:inherit;}
            h5{color:inherit;}
        }
      h4{margin-top:2rem;font-family:$font-family; color:$white;font-size:1.25rem;}
      h5{font-family:$font-family; color:$white;}

      @include bp(md){
          h4{color:inherit;}
          h5{color:inherit;}
      }
}   
}

.Testimonial{
    margin-top:10rem;
     @include bp(md){
         margin-top:3rem;
     }
    h2{font-family:$font-family;color:$blue;font-size:$head-size;
        @include bp(md){
            font-size:$head-md;
          }
        @include bp(sm){
            font-size:$head-small;
        }
    }

    .TestBox{
        position: relative;

        &::before{
            content: "";
            position: absolute;
            width: 4rem;
            height: 4rem;
            border-radius: 100%;
            background: #3e46e9;
            bottom: 0rem;
            left: -15rem;
            z-index: 0;
         }

         .carousel-inner{
            .carousel-item{
                min-height:350px;             
            }
        }
    }
    .carousel{
        position:relative;
        &::before{
            content: "";
    position: absolute;
    width: 8rem;
    height: 8rem;
    border-radius: 100%;
    background: #3e46e9;
    top: -4rem;
    right: -4rem;
    z-index: 0;
  
    @include bp(md) {  
        right:0rem;
        width: 4rem;
        height: 4rem;
        top:-3rem;
    }

         }

         &::after{
            content: "";
            position: absolute;
            width: 6rem;
            height: 6rem;
            border-radius: 100%;
            background: #3e46e9;  
            top: 1rem;
            left: -8rem;
            z-index: 0;
         }


    .carousel-inner{
        border-radius: 30px;
        box-shadow: 0px 0px 45px rgba(0,0,0,0.15);
        margin-top:2rem;
         
        
    }
}
    .card{
        border:none;padding-top:1rem;
        // &::before{
        //     content:"";
        //     background: url("../images/future-students.png") top left no-repeat;  
        //     width:100%;height:100%;top:-2rem;
        //     position:absolute;overflow:visible;
        // }

        .card-title{font-family:$font-family;}
        .card-subtitle{font-family:$font-family;font-size:1rem;}
    }
    .ArrowLeft{
    position: relative;
    left: -5rem;
    color: #636363;
    font-size: 5rem;
       
    @include bp(sm) {  
        left:0rem;
        font-size:3rem; 
           
     }

    }
    .ArrowRight{
    position: relative;
    right: -5rem;
    color: #636363;
    font-size: 5rem;
       
    @include bp(sm) {  
        right:0rem;
        font-size:3rem;  
     }

    }
}

.videoBoxMain{
    width:100%;
}

.player-wrapper {
    position: relative;
    padding-top: 40.25%; /* 720 / 1280 = 0.5625 */

    @include bp(sm){
        padding-top:56.25%;
    }
    
  }
  
  .react-player {
    position: absolute;
    top: 0rem;
    left: 0;
    
  }

  @include bp(sm) {  
      h2{font-size:1.5rem;}
      h3{font-size:1.5rem;}
  }

  .Blogbox.careerBox.EventBox.homeTopic{
      margin-top:5rem;
    //   h1{color:$white;}
       h1{color:$blue;}
      @include bp(md) {  
        h1{color:$blue;}
      }
      @include bp(sm) {  
          margin-top:0rem;
        //   h1{color:$white;}
          h1{color:$blue;}
      }
  }