@import 'global';


    .BannerBox.discussionBanner.faqBanner {
        .sixteen-nine{
           &::before{
            background: url(../images/faq.png) center center no-repeat;
            padding-top: 39.25%;background-size:cover;
           }
        }
    }

    .faqBoxMain{
        margin-top:5rem;
        @include bp(md){
            margin-top:2rem;
        }
         .askeBox{
             h4{color:$blue;font-family:$font-family;font-weight:400;font-size:1.5625rem;}
             h2{font-family:$font-family;margin:2rem 0rem 1.5rem;font-size:2.25rem;
             
                @include bp(md){
                    margin-top:1rem;
                    font-size:$head-md;
                }
                @include bp(sm){
                    font-size:$head-small;
                }
            }
             p{font-family:$font-family;color:#6d6e71;}
         }

        .CheckButtonFaq {

        *{
            color:$white;  
        }
        margin-top: 40px;
       
        font-size: $header-font-size;  
        text-transform: capitalize;
        background:$blue;
        padding:0.7rem 2rem;border-radius:35px;
        // transition: 200ms;  
        font-family: $font-family;
        margin: 2rem 0rem;    

        &:hover{
            background:$light-blue;
        }
        @include bp(md) {  
           
            // padding:0.2rem 1rem;  
            // font-size:0.875rem;
           
         }
    }



    
    }


    .accordionBox{

        .accordion {
            width: 100%;
            max-width:625px;
            padding-left:2rem;
            *{
                font-family:$font-family;
               
            }
          }
          .panel {
            // background-color: #f0ebe1;
          }
          .panel__label {
            position: relative;
            display: block;
            width: 100%;
            background: none;
            border: none;
            text-align: left;
            padding: 10px 0px 10px 25px;
            font-weight: 500;
            // font-size: 17px;
            font-family: inherit;
            -webkit-transition: color 0.2s linear;
            transition: color 0.2s linear;
            cursor: pointer;
            @include bp(md){
                h4{font-size:1.25rem;}
            }
          }
          .panel__label:focus {
            outline: none;
          }
          .iconBox{
              width:30px;height:30px;background:$blue;position:absolute;border-radius:100%;display:inline-block;margin-right:1rem;top:10px;left:-20px;
          }
          .panel__label .iconBox:after,
          .panel__label .iconBox:before {
            content: '';
            position: absolute;
            left: 0%;
            right:0%;
            top: 50%;
            margin:0 auto;
            width: 12px;
            height: 2px;
            background-color:$white;
          }
          .panel__label .iconBox:before {
            -webkit-transform: rotate(-90deg);
                    transform: rotate(-90deg);
            -webkit-transition: -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
            transition: -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
            transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
            transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1), -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
          }
          .panel[aria-expanded='true'] .panel__content {
            opacity: 1;
          }
          .panel[aria-expanded='true'] .panel__label {
            color: #957029;
          }
          .panel[aria-expanded='true'] .panel__label .iconBox:before {
            -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
          }
          .panel__inner {
            overflow: hidden;
            will-change: height;
            -webkit-transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
            transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
          }
          .panel__content {
            margin: 5px 25px 25px;
            // font-size: 14px;
            color:#6d6e71;
            opacity: 0;
            -webkit-transition: opacity 0.3s linear 0.18s;
            transition: opacity 0.3s linear 0.18s;
          }
          .panel:not(:last-child) {
            margin-bottom: 3px;
          }
    }


    .accordionBox .panel[aria-expanded=true] .panel__label {
      color:$h2color;
  }