@import './style/global.scss';  

a:hover{text-decoration:none;color:inherit;}
a{color:inherit;}     

.accordionBox .panel[aria-expanded=true] .panel__label {
  color: #414042;
}


*{margin:0rem;padding:0rem;}
body{line-height:1.5; font-family:'gotham rounded';}
h1, h2, h3, h4, h5{color:#414042; }
button:focus{outline:none;}
ul li {list-style:none;}
a:hover{text-decoration:none;color:inherit;}
a{color:inherit;}   
.link{color:inherit;}
textarea:focus{outline:none;}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
 
  
  -webkit-box-shadow: 0 0 0px 1000px #ebebeb inset;
  border-radius:40px;
  transition: background-color 5000s ease-in-out 0s;
  background-color:none;
  
}      

.noFound{
  margin-top:8rem;
}





.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: inherit;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
