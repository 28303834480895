@import 'global';
$size: 22rem;
$size1: 24rem;
// $size2: 10rem;

// @keyframes rotate {
//     from {
//       transform: rotate(0deg)
//     }
//     to {
//       transform: rotate(90deg)
//     }
//   }

.signNav{
    background: url(../images/wave.png) top left no-repeat;
    background-size:cover;padding-bottom:1rem;
    transition: 0.1s;
    top:6rem;

    @include bp(lg){
        padding-bottom:0rem;
    }
}
.sticky-top.sign-light{
    background: url(../images/wave.png) top left no-repeat;
    background-size:cover; padding-bottom:1rem; 
    transition: 0.7s;
    // top:6rem;
       display:none; 
    @include bp(lg){
        padding-bottom:0rem;
    }
}


.logInBox{


   
    margin:5rem auto 0rem;display:table;position:relative;
    &::before{
           content:"";
           position:absolute;
           background:url(../images/icon-4.png) top left no-repeat;
           width:4rem;height:4rem;
           right:4rem;
           top: 10rem;
           z-index:0;
    }
    &::after{
        content:"";
        position:absolute;
        background:url(../images/icon-1.png) top left no-repeat;
        width:4rem;height:4rem;
        left:4rem;
        top: 5rem;
        z-index:0;
 }
    .outLine{
          position:relative;
        &::before{
            content:"";position:absolute;   
            height: $size1;
        width: $size1 * 2;
        border-top-left-radius: $size1 * 2;
        border-top-right-radius: $size1 * 2;
        background:$white;
        display: inline-block;
        left: -0.2rem;
        top: -2rem;
        border: 3px solid #d6d7c8;

        }

        &::after{
            content: "";
            position: absolute;
            background: #fff url(../images/icon-plus.png) top 5rem left no-repeat;
            height: 20rem;
            width: 4rem;
            top: -3rem;
            right: 2rem;
            /* margin-top: 5rem; */
            z-index: 1;
            background-size: 4rem;
            border-bottom-right-radius: 20rem;
            border-top-right-radius: 20rem;
            transform: rotate(-45deg);
        

        }
    }
    .mainBox{
        padding-left:1.7rem;
       
    .top{
        height: $size;
        width: $size * 2;
        border-top-left-radius: $size * 2;
        border-top-right-radius: $size * 2;
        background: #f8f6f1;
        display: inline-block;
        position:relative;
        &::before{
            content: "";
            position: absolute;
            background:$white;
            height: 20rem;
            width: 4rem;
            top: -2rem;
            left: 0rem;
            z-index: 0;
            background-size: 3rem;
            border-bottom-right-radius: 20rem;
            border-top-right-radius: 20rem;
            transform: rotate(217deg)

        }

        &::after{
            content: "";
            position: absolute;
            background: url(../images/icon-square.png) top left no-repeat;
            height: 4rem;
            width: 4rem;
            top: 4rem;
            left: 5rem;

        }

        .signMainbox{  
               position:relative;
               &::before{
                content: "";
                position: absolute;
                height: 1.2rem;
                width: 1.2rem;
                border-radius: 100%;
                background: #25cae7;
                left: 12rem;
                top: 3rem;
               }
               &::after{
                content: "";
                position: absolute;
                height: 1rem;
                width: 1rem;
                border-radius: 100%;
                background: #887419;
                right: 0.5rem;
                bottom: 1rem;
                z-index:2;
               }

               @include bp(sm){
                   p{font-size:0.875rem;}
               }
              
        }


         h2{
             font-family:$font-family;font-size:2rem;margin-top:1rem;margin-bottom:1rem;
         }
        .blueButton{
            background:#5196f4;color:$white;font-size:1.125rem;min-height:40px;min-width:47%;
            margin-bottom:8px;
            @include bp(md){
                margin-bottom:0px;
            }
        }
    }   
    .tabBox{padding-right:2rem;
       .formMainBox{
           border:1px solid #ccc;
           border-top:2px solid  #5196f4; padding-bottom:2rem;margin-top:-0.5rem;background:$white;
           padding: 0rem 3.5rem 3rem 4rem;  
           .MuiIconButton-label{margin-left:10px;}
          .kep-login-facebook{padding:0.5rem; font-size:0.875rem;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
            float:right;
             width:100%;
        }
            .orBox{
                font-family:$font-family;
            }
        
            .googleBox{ width:100%;}

            input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
 
  // -webkit-text-fill-color: green;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  border-radius:0px;
  transition: background-color 5000s ease-in-out 0s;
  background-color:none;
  
}
        
        }
    }
    .PrivateTabIndicator-colorSecondary-13{background:none;}
    .MuiTabs-scroller{position:absolute;bottom:0rem;}
    .MuiTabs-flexContainer{justify-content: space-between; padding: 5px 3px 0rem;}
    .MuiFormLabel-root.Mui-focused{color:#5196f4;}
    .MuiCheckbox-colorSecondary{
         &:hover{background:none;}
        &.Mui-checked{color:#5196f4;
     }
    }
    .MuiInputAdornment-root{display:none;}
    .MuiCheckbox-root{padding-left:0rem;}
    .MuiButton-containedPrimary{background:#5196f4;border-radius:0rem;min-height:40px;box-shadow:none;font-size:1.125rem;
      &:hover{background:$blue;}
    }
   
}
.passwordBox{
    h6{cursor:pointer;}
}


@include bp(md) {  
        margin-top:2rem;
        &::before{display:none;}
        &::after{display:none;}
     .outLine{
        &::before{width:100%; left:0rem;display:none;}   
         &::after{display:none;}   
     }
     .mainBox{
         &.outLine{padding-left:1rem;}
         .tabBox{
             padding-left:1.4rem;
             .formMainBox{
                 padding:0rem 15px 2rem 15px;margin-top:0rem;
             }
         }
         .top{
             width:100%;
            &::before{display:none;}  
            &::after{display:none;}  
            .signMainbox{
                &::before{display:none;}
                &::after{display:none;}  
            }

         }
     }
       
 }


}


