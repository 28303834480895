@import 'global'; 


.Blogbox.careerBox.EventBox.PastTraing{


    .CheckButton{
        padding:0.2rem 1rem;
        margin-left:0.2rem;margin-right:0.2rem;font-size:0.875rem;
        box-shadow:none;
       
    }

    .SpeakerBox{
        .Speaker{
            h4 {font-size:1.375rem;}
        }
    } 
    .card{
        .headBox{
            h4{font-size:1.175rem;}
            h5{font-size:1.125rem;}
        }
        .dateBox{
            padding-top:0.5rem;
            h3{font-size:1.625rem;}  
            h5{font-size:0.9375rem;}
            h6{font-size:1.0625rem;}

            @include bp(sm){
                right:inherit;
            }
        }
        @include bp(sm){
            margin-top:1.5rem;
        }
    }
    
}
.Blogbox.careerBox.scheduleBox{

      .headBox{
          h2{font-size:2.125rem; 
          @include bp(md){
              font-size:1.75rem;
          }
        }
      }
    .CheckButton{
       margin-top:4rem;
       font-size:1.25rem;
        svg{
            font-size:32px;
        }

        @include bp(sm){
            font-size:1rem;
            svg{
                font-size:36px;
            }
    
        }

       @include bp(md){
           margin-bottom:2rem;
           margin-top:0rem;
       }
    }
    .checkBtnSchedule{
        font-size:1.5rem;
        border-radius:45px;
        padding:0.7rem 3rem;
        @include bp(md){
            font-size:1.5rem; 
            margin-top:2rem; margin-bottom:2rem;
        }
        @include bp(sm){
            font-size:1.25rem;   
            margin-top:2rem; margin-bottom:2rem;
        }
    }
}



