@import 'global';

.BannerBox.discussionBanner.contactBanner {
    .sixteen-nine{
       &::before{
        background: url(../images/contact.png) center center no-repeat;background-size:cover;
        padding-top: 39.25%;
       }
    }
}

.commentBox.contactBox{
    h3{color:$blue;margin-bottom:1.25rem;line-height:1.5;font-size:1.5625rem;}
    h2{font-size:2.5rem;
      @include bp(md){
        font-size: 1.625rem;
      }
    }
    p{color:#808285;font-size:1.5625rem;
        @include bp(md){
            font-size: 1.25rem;
        }
    }   
    .mapBox{
        min-height:633px;
        width:100%;
        @include bp(md){
            min-height:350px;
        }
    }
    .supportBox{
        font-size:2rem;
        @include bp(md){
            font-size: 1.5rem;
        }
    }
}

.careerForm.feedBackForm.commentForm.contactForm {
    .MuiFilledInput-input {
        padding-top: 45px;
        padding-left: 1.8rem;
    }
    .MuiInputLabel-root{
        padding-top:10px;
    }
    .CheckButton{
        font-size: 1.1875rem;
    }

}

.addressBox{
    margin-top:5rem;
    @include bp(md){
        margin-top:2rem;
    }
    background:url(../images/address.png) left top no-repeat ;
    padding-top:9rem;padding-bottom:6rem;font-family:$font-family;
    background-size:cover;

    @include bp(md){
        padding-top:6rem;padding-bottom:2rem;
    }

    h3{
        color:$blue;font-family:$font-family;margin-bottom:1rem;
    }
    .textBox{
        *{
            color:#414042;
            font-size:1.25rem;
             @include bp(md){
                 font-size:1rem;
             }
        }
    }
}