@import 'global';  



.Newsletter{
    margin-top:5rem;
    background-image: linear-gradient(-90deg, #3933e9, #4c6ce8);
    padding-top:1rem;padding-bottom:1rem;
    // color:$white;
    @include bp(md) {      
          margin-top:2.5rem;
          padding-bottom: 2.5rem;
    }
    h3{color:$white;font-family:$font-family;font-size:1.5625rem;}
    p{font-family:$font-family;}
    .CustomForm{ position:relative; 
    .TextBox{
        min-width:28rem;margin-top:0.7rem;   
        @include bp(md) {  
            //  min-width:75%;      
            min-width:60%;
               
         }

            
        .MuiFilledInput-root{border-radius:40px 0px 0px 40px;border:none;background:#ebebeb;
          &::before{display:none;}
          &::after{display:none;}    
        } 
        .MuiFilledInput-input{
            padding-left:29px;
        }  
        .MuiFormLabel-root{
            color:#2f2f2f;margin-left:1rem;font-weight:bold;
        }
        .MuiFilledInput-input{padding-top:30px;}

        
    }

    .MuiButton-contained{
        position: absolute;
    z-index: 2;
    left: 22rem;   
    box-shadow: none;
    background: white;
    border-radius: 40px;
    /* padding: 1rem; */
    padding: 0rem 2rem;
    height: 59px;margin-top:0.7rem;font-weight:600;
       
    @include bp(md) {  
        left:9.5rem;
          
    }


    }
}
}

.FooterBox{
    background:url("../images/footer.jpg") no-repeat;  
    background-size:cover;
    padding:0rem 0rem;
    padding-bottom:2rem;
    // color:$white;
    h4{color:$white;font-family:$font-family;margin-bottom:1.5rem;margin-top:2rem;font-size:1.3125rem;}
    .nav-link{color:$white;padding-left:0rem;font-family:$font-family;
      &:hover{
          color:$white;
      }
    }
    .MuiFormControl-root{
        background:$white;margin-top:0.5rem;height:45px;display:flex;
        .MuiOutlinedInput-notchedOutline{
            border:none;
        }
        .MuiInputLabel-formControl{top:-4px;  color:#414042;font-family:$font-family;}
        .MuiInputLabel-formControl.Mui-focused{top:7px;}
        .MuiInputLabel-formControl.MuiFormLabel-filled{top:7px;}

        
    }
    .submitBox{box-shadow:none;background:#f26522;margin-top:0.5rem;border-radius:0px;display:block;font-family:$font-family;}

    .SocialBox{
        .list-inline-item{
            width: 35px;
    height: 35px;
    background: #fff;
    line-height: 35px;
    border-radius: 100%;
    text-align: center;
    cursor: pointer;
    color: #727996;
    font-size: 13px;
    transition: 0.3s;

    &:hover{
        background:#f26522;
        color:$white;
    }


        }
    }
}
.FooterBottom{background:#191f5e;color:$white;padding-top:1rem;}
.textAreaBox{width:100%;
  &::placeholder{color:#414042;font-family:$font-family;}
}
.textLable{
    #outlined-basic{padding-left:1rem;padding-top:1rem; color:#272727;font-weight:400;padding-left:15px;padding-top:0.5rem;margin-bottom: -8px;}
}

.custom-footer{   
    .Newsletter{margin-top:0rem;}   
}