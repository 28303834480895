@import 'global';

.Blogbox.careerBox.EventBox.row-Box{
    margin-top:0rem;
    padding-bottom:0rem;
    .card{
        margin-top:0rem;border-radius:20px;
        img{
            border-radius: 20px 20px 0px 0px;
            width:100%;
        }
        .card-body{
            padding-bottom:0.5rem;
            .card-text{
                padding:0rem 1.2rem;
            }
        }
    }
    .card-footer{
        background:none;border:none;
    }

    [class*="col-"] {
        margin-top: 5rem;
        @include bp(md){
            margin-top:2rem;
        }
      }
      
}

.commentBox{
    margin-top:4rem;
    h2{
        font-size:2.5rem;
    }
    @include bp(md){
        margin-top:2rem;
        h2{font-size:1.625rem;}
    }

   
   
    *{
      font-family:$font-family;
    }
    .media{
        margin-top:3rem;   
        .imgBox{
            width: 120px;
            height: 120px;
            border-radius: 100%;
            margin-right: 1rem;
             
            img{
                border-radius:100%;  
                width:100%;
                height:100%;
            }
        }
        .media-body{
            h5{
                margin-right:2rem; 
            }
            h6{
                 line-height:1.6;    color:#808285; 
            }
            button{
                color:#fd7921;font-size:1rem;
            }
            p{
                color:#808285;
            }

            @include bp(md){
                flex:0 0  100%;
             }
        }

        @include bp(md){
           flex-wrap:wrap;
           margin-top:1rem;
        }
    }
}

.careerForm.feedBackForm.commentForm{
    .MuiFormControl-root{
        background:none;border:1px solid #c3c3c3;
    }
    .MuiFilledInput-root{
        background:none;
    }
    .textAreaBox{
        background:none;border:1px solid #c3c3c3;
        height:7rem !important;
        min-height:7rem;
    }
    .CheckButton{
        margin:0rem;box-shadow:none;
        padding-left:1.5rem;padding-right:1.5rem;
    }
}