@import 'global'; 

.BannerBox.TrainingBox{
   margin-top:-6px;

  
 .sixteen-nine{
    &::before {  
        content:"";
        background: url(../images/past-training.jpg) center center no-repeat;
        background-size:cover;
     
      }
 }

 

}

.BannerBox.TrainingBox.upcoming{
    margin-top:-6px;
 
   
  .sixteen-nine{
     &::before {  
         content:"";
         background: url(../images/overview.png) center center no-repeat;
         //background: url(../images/overview.jpg) center center no-repeat;
         background-size:cover;

         @include bp (sm){
             background:url(../images/overview-mob.png) center center no-repeat;
             padding-top:35rem;
         }
      
       }
  }
 
  
 
 }

 
.BannerBox.TrainingBox.pastWebinar{
    margin-top:-6px;
  .sixteen-nine{
     &::before {  
         content:"";
         background: url(../images/past-webinar.jpg) center center no-repeat;
         background-size:cover;
      
       }
  }
 
 }

 .BannerBox.TrainingBox.upComeingWebinar{
    margin-top:-6px;
  .sixteen-nine{
     &::before {  
         content:"";
         background: url(../images/upcoming-webinar.jpg) center center no-repeat;
         background-size:cover;
      
       }
  }
 
 }


.careerMain{
    position:relative;

    &::before{
        content: "";
        background:url(../images/team.jpg) bottom center no-repeat;   
        position: absolute;
        bottom: 0rem;
        width: 100%;
        height: 100%;
        background-size: contain;
       }


}
.Blogbox.careerBox{
      *{
          color:#414042;
      }
    .card{
        padding:0rem 2rem 2rem;
        border-radius: 20px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
          @include bp(sm){
              margin-top:0rem;
          }
        
        .card-body{
            padding:1.25rem 0rem 0rem;
            .card-text{
               font-family:$font-family;
                // font-size: 1.25rem;
                text-align:justify;
                @include bp(sm){
                    font-size:1rem;
                }
            }
        }

.dateBox{
    background:$blue;padding: 1.5rem 0.7rem 0.2rem 0.7rem;

      @include bp(sm){
        position: absolute;
        top: 0rem;
        right: 1rem;
      }
    *{
        color:$white;
    }
    h3{font-family:$font-family;font-size:1.625rem;
        @include bp(sm){
            font-size:1.75rem;
        }
    }
    h5{font-size:1.1875rem;
        @include bp(sm){
            font-size:1rem;
        }
    }
    h6{background: #252525;font-size:1.3125rem;padding:0.2rem 0.5rem 0.2rem 0.5rem;
    
      @include bp(sm){
          padding:0.2rem;
          font-size:1rem;
      }
    }
}

.headBox{
    h2{margin-top:0rem;font-family:$font-family; }
    h5{font-family:$font-family;
     svg{margin-right: 0.5rem;
        width: 20px;
        height: 20px;}
    }
} 


.SpeakerBox{
     margin-top:0rem;margin-bottom:2.5rem;
    .Speaker{
        img{
            width:120px;height:120px;border-radius:100%;
        }
      h4{margin-top:0rem;font-family:$font-family; color:inherit;font-size:1.75rem;
         @include bp(sm){
             font-size:1.625rem;
         }
    }
      h5{font-family:$font-family; color:inherit;padding-top:1rem;font-size:1.675rem;margin-bottom:0rem;
        @include bp(sm){
            font-size:1.3125rem;
        }
    }
}   
}

    }

    .CheckButton
    {

        *{
            color:$white;  
        }
        margin-top: 40px;
       
        font-size: $header-font-size;  
        text-transform: capitalize;
        background:$blue;
        padding:0.7rem 2rem;border-radius:35px;
        // transition: 200ms;  
        font-family: $font-family;
        margin: 2rem auto 5rem auto;
        display: table;
        box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.2);
        


        @include bp(md) {  
           
            // padding:0.2rem 1rem;  
            // font-size:0.875rem;
           
         }
    }

    .checkBtnSchedule{
        font-size:1.5rem;
        border-radius:45px;
        padding:0.7rem 3rem;
        @include bp(md){
            font-size:1.5rem; 
            margin-top:2rem; margin-bottom:2rem;
        }
        @include bp(md){
            font-size:1.25rem;   
            margin-top:2rem; margin-bottom:2rem;
        }
    }

}

.careerMain.eventMain{
    &::before{
        display:none;
    }

    .imgBoxtraining{
        height:110px;
        width:100%;
        background:#ECECEC;
        border-radius: 20px 20px 0px 0px;
        img{
            width:100%;
            height:100%;
            display: none;
        }
    }
    
}

.Blogbox.careerBox.EventBox{



    
    .row {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -8px;
        margin-left: -8px;
         
        @include bp(md){
            margin-right: -15px;
            margin-left: -15px;
        }
    }

    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 8px;
        padding-left: 8px;
        margin-top:8rem;
        @include bp(md){
            padding-right: 15px;
            padding-left: 15px;
            margin-top:2rem;
            &:first-of-type{
                margin-top:5rem;  

            }
  
        }
        @include bp(sm){
            margin-top:1rem;
            &:first-of-type{
                margin-top:5rem;  

            }
        }
    }




    padding-bottom:0rem;margin-top:0rem;
   h1{font-family:$font-family;color:$h2color;font-size:$head-size;top:3rem;
    @include bp(md){
        font-size:$head-md;
        top:0rem;
    }
     @include bp(sm){
         font-size:$head-small;
         top:3rem;

     }
   }
   .card{padding:0rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);margin-top:0rem;
    .dateBox{position:absolute;z-index:1;border-radius:6px 0px 0px 0px}
    // img {position:absolute; z-index:0;}
    .SpeakerBox{
        margin-bottom:0rem;
       .Speaker{
        margin-top: -4rem;
        h4{margin-top:1rem; font-size:1.375rem;}
        .imgBox{
            width: 120px;
    height: 120px;
    
    border-radius: 50%;
    margin: 0 auto;
           img{height:100%; width:100%;}
        }
       }
       
    }

    .dateBox{
        padding-top:0.5rem;
        h3{font-size:1.625rem;}
        h5{font-size:0.9375rem;}
        h6{font-size:1.0625rem;}

        @include bp(sm){
            right:inherit;
        }
    }
    @include bp(sm){
        margin-top:1.5rem;
    }

    .headBox{
        h4{
            font-family:$font-family;
            font-size:1.175rem;
        }
        h5{
            font-size:1.125rem;
            svg{ *{ color:$blue;}}  
        }
    }
    .card-body{
        padding:0rem 0rem 0.5rem;
    }
    .card-footer{
        padding:0rem 0rem 1rem;
        background:none;
        border:none;
    }
}
.CheckButton{
    margin: -1rem auto 4rem auto;   
    padding: 0.4rem 2rem;  
    @include bp(md) {
        margin-bottom:0rem;
    }
}

}

 .headBoxRegister{
    font-family: $font-family;
     color:#6C6D71; margin-top:3rem;font-size:2.125rem;

        @include bp(sm){
            font-size:1.565rem;  
        }
     }

.customFooter{margin-top:-6rem;
    @include bp(md) {  margin-top:-3rem;}
}


// dialouge

.careerForm{
     *{color:#7E7F82;font-family:$font-family;}
     svg{
        font-size:3.688rem;
        top: -1rem;
       }
    .MuiFormControl-root{border-radius:40px;border:none;background:#ebebeb;display:flex;
           
      }   
    .MuiInputLabel-root{ font-family:$font-family;padding-left:1.5rem;font-size:1.25rem; top: -4px;
          @include bp(sm){
              font-size:1.165rem;
          }
    
       }
    .colorBox{color:#FD7F2B;}
    .MuiFilledInput-root{border-radius:40px;border:none;background:#ebebeb; 
        &::before{display:none;}
        &::after{display:none;}    
      }   
      .MuiInput-underline:before{display:none;}
      .MuiInput-underline:after{display:none;}
      .MuiFilledInput-input{padding-top:25px;padding-left:1.8rem;}
      .MuiSelect-select.MuiSelect-select{padding-left:2rem;
    
      
    }
      .MuiSelect-select:focus{background:none;}
      label + .MuiInput-formControl {
       line-height:1.8em; margin-top: 16px;
    }
      .CheckButton
      {
  
          *{
              color:$white;  
          }
          margin-top: 40px;
         
          font-size: 1.5rem;  
          text-transform: capitalize;
          background:$blue;
          padding:0.7rem 2rem;border-radius:35px;
          // transition: 200ms;  
          font-family: $font-family;
          margin: 0rem auto 2rem auto;
          display: table;
          box-shadow: 0px 5px 2px rgba(0,0,0, 0.2);

          @include bp(sm) { 
             font-size:1.165rem;
          }
  
          @include bp(md) {  
             
              // padding:0.2rem 1rem;  
              // font-size:0.875rem;
             
           }
      }
      .MuiButton-root:hover{background:$light-blue;color:$white;}

}



.careerForm.feedBackForm{
   
     .textAreaBox{
        background:#ebebeb; height: 17rem !important;min-height:17rem;border:none;border-radius:40px;padding:2rem;
        font-size:1.25rem;  

        @include bp(sm){
            font-size:1.165rem;
        }

     }
     .CheckButton{
         padding-left:4.5rem;padding-right:4.5rem;
     }

}

.Blogbox.careerBox.EventBox{
    .CheckButton{
            box-shadow:none;
    }
}

.checkboxIcon {
    svg{font-size:1.5rem;}
}




.dialougemain{
.boxMain{border-radius:30px !important;}
}


.Testimonial.TrainingGallery{
    .card{
        padding-top:0rem;
    }
    .TestBox{
        .carousel-inner{
            .carousel-item{
                min-height:auto;   
            }
        }
    }
}
