@import 'global';
@import 'node_modules/react-modal-video/scss/modal-video.scss';




.imageGridItem {
    display: inline-block;
    box-sizing: border-box;
    float: left;
    padding: 10px;
    overflow: hidden;
    position: relative;
    transition: all .5s linear;
    transition-delay: .1s;
}
.imageWrapper {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transition: all .5s linear;

    cursor: pointer;
    box-shadow:0 10px 6px -6px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.imageGridItem:hover .imageWrapper{
    background-position: right;
}

.imageGridItem a {
    display: none;
    font-size: 100%;
    color: #ffffff !important;
    text-align: center;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 50px;
    cursor: pointer;
    text-decoration: none;
}

.imageGridItem:hover a, .imageGridItem:focus a {
    display: block;
}

.imageGridItem:hover .imageWrapper:before, .imageGridItem:focus .imageWrapper:before {
    display: block;
}

.imageWrapper:before {
    content: "";
    display: none;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(29, 38, 41, 0.65);
}
.column1 {
    width: 100%;
    transition: all .5s linear;
}
.column1Image {
    float:left;
    width:30%;
    margin-right: 10px;
    padding-bottom: 30%;
    transition: all .5s linear;
}


.lightbox {
    /** Default lightbox to hidden */
    /*display: block;*/
    opacity: 1;
    /** Position and style */
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.88);
    visibility: visible;
    /*transition: visibility .3s, opacity .3s linear;*/
    transition: all .5s ;
}

.lightbox img {
    /** Pad the lightbox image */
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    /*padding: 10px;*/
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    opacity:0;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    pointer-events:none;

}

.lightbox img:global(.opaque) {
    opacity:1;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=1);
    pointer-events: auto;

}

.hide {
     /*display:none;*/
     opacity: 0;
    visibility: hidden;
    transition: visibility .3s, opacity .3s linear;
    /*transition: all .5s;*/

}





.buttons {
    margin: 0 auto;
    width: 100%;
    display:inline;
}



.buttons a {
    margin: 10px;
    /*box-shadow:0 0 10px rgba(0, 0, 0, 0.5);;*/
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.buttons a:link, .buttons a:visited {
    background-color: white;
    color: black;
    /*border: 2px solid green;*/
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 2px;
}

.buttons a:hover, .buttons a:active {
    background-color: rgba(0, 0, 0, 0.078);
    box-shadow:0 10px 6px -6px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;

    /*color: white;*/
}

.secondaryButton a:link, .secondaryButton a:visited {
    background-color: #00bcd4;
    color: white
}

.secondaryButton a:hover, .secondaryButton a:active {
    background-color: rgb(0, 161, 183);
    box-shadow:0 10px 6px -6px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset ;
}

.defaultButton a:hover, .defaultButton a:active {
    background-color: rgba(0, 0, 0, 0.078) ;
    box-shadow:0 10px 6px -6px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.defaultButtonSelected {
    background-color: rgba(0, 0, 0, 0.2) !important;

}
.secondaryButtonSelected {
    background-color: rgb(0, 139, 161) !important;

}
.info {
    transition: all 5s;
    transition-delay: 2s;
}


.aricleBox{
    .MuiDivider-root{
        margin:0rem -1rem;
        background-color:#A2A2A2;
        height:1px;
        margin-top:0.5rem;

        @include bp(md){
            margin:1.5rem 0rem 0rem;
        }
    }

    // .mainBox{
    //          &:nth-last-child(0){  
    //         .MuiDivider-root{    
    //             display:none;
    //         }  
    //     }
    //     &:nth-last-child(1){  
    //         .MuiDivider-root{    
    //             display:none;
    //             @include bp(md){
    //                 display:block;
    //             }
    //         }   
    //     }

    // }
    .media{
       
        @include bp(md){
            flex-direction:column;
            height:auto !important;
        }
        .media-body{
              h5{
                  font-family:$font-family;color:$h2color;font-size:1rem;
              }
              h6{margin-top:1rem;}
            @include bp(md){
                h5{font-size:1.125rem;}
               margin-top:1rem;
            } 
        }
    }
    .imgWidth{
        width:5rem;  
        height:auto;  
        img{width:100%; height:100%;}
        @include bp(md){
            width:50%;
        }
    }
}



.categoryMenu{
  display: flex;
  justify-content: center;
  margin-top: 5rem;   
  width:100%;

  @include bp(md){
        padding:0rem 1rem;  
        margin-top:2rem; 
  }

  input{
    display: none;
  }
  
  label{
    // background: #faa7b7;
    // color: #fff;
    // font-weight: 700;
    padding: 5px 15px;
    border-radius: 25px;
    transition: .3s;
    cursor: pointer;
    color:#131313;
    font-family:$font-family;
    &:hover{
        background:$blue;color:$white;
    }
  }
  
  input:checked~label{
    background:$blue;
    padding: 5px 15px;
    border-radius: 25px;
    color:$white;
    // box-shadow: 5px 2px 15px $blue;
  }
  
  ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 0;
    @include bp(md){
        flex-wrap: nowrap;
        overflow-x:scroll; 
        justify-content: flex-start;
        margin-bottom:0rem;    
    }
  }
  
  li{
    margin: 0px 10px;
  }

  
}




.categoryMenu1{
    display: flex;
    justify-content: center;
    margin-top: 5rem;   
    width:100%;  
    white-space: nowrap;     
    @include bp(md){
          padding:0rem 1rem;  
          margin-top:2rem; 
    }
   
    ul{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      list-style: none;
      padding: 0;
      @include bp(md){
          flex-wrap: nowrap;
          overflow-x:scroll; 
          justify-content: flex-start;
          margin-bottom:0rem;    
      }
    }
    
    li{
      margin: 0px 5px;
      padding: 5px 5px;
      border-radius: 25px;
      transition: .3s;
      cursor: pointer;
      color:#131313;
      font-family:$font-family;
      &:hover{
          background:$blue;color:$white;
      }
      &.active{
        background:$blue;color:$white;
    }
    }
  
    
  }


.articleSingle{
    margin-top:5rem;
    ul li { 
        list-style-type: disc;
        margin-bottom:1rem;
    }
    ol li { 
        list-style-type: disc;
        margin-bottom:1rem;
    }
    .ArticleSingle-root-1{
        box-shadow: 0px 2px 15px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border:none;
    }
    .MuiTypography-h4{
        font-size:1.75rem;font-family:$font-family;color:$h2color;
    }
    .MuiTypography-h6{
        font-family:$font-family;color:$h2color;
    }
    .MuiPaper-rounded{border-radius:20px;}
    .MuiTypography-colorTextSecondary{
        color: #6d6e71;
    }
    .MuiTypography-body2{
        font-size:1.25rem;margin-top:1.25rem;
    }
    .MuiCardContent-root {
        padding: 1.5rem 2rem;
    }

    .jumbotron{
        padding: 1.5rem 2rem;margin-top:3rem;
        h3{font-style: italic;font-family:$font-family;margin-left:3rem;letter-spacing: -1px;font-size:1.625rem;
            @include bp(md){
                font-size:1.25rem;
            }
        
        }

        .quoteBox{
            border-radius: 100%;
    width: 80px;
    height: 80px;
    background:$white;      
    display: inline-block;
    text-align: center;
    line-height: 80px;
    position: absolute;
    top: -2rem;
    left: -2rem;
        svg{
            font-size:4rem;
            transform: rotate(180deg);color:$blue;
        }
    }
}

    @include bp(md){
         margin-top:2rem;
        .ArticleSingle-media-2{height:12rem;}
        .MuiTypography-h4{font-size:1.5rem;}
    }


}

.boxMain{

.dialouge{
padding:0rem;overflow:hidden;
&:nth-child(1){
    padding-top:0rem;
}
.player-wrapper {
    position: relative;
    padding-top: 76.25%; /* 720 / 1280 = 0.5625 */
    overflow:hidden;
    @include bp(sm){
        padding-top:56.25%;
    }
    
  }
  
  .react-player {
    position: absolute;
    top: 0rem;
    left: 0;
    
  }
}

}
.videoLightbox{
    margin-top:5rem;
    // .container {
    //     display: flex;
    //     align-items: stretch;
    //   }
    @include bp(sm){
        margin-top:2rem;
    }
    .imgBox{
        .MuiButton-label{
            width:100%;height:340px;
            img{width:100%;height:100%;}
            @include bp(md){
                height:270px;
            }
        }
    }
    .boxIcon{
        border:3px solid rgba(255, 255, 255, 0.3);position:absolute;border-radius:100%;
    .playIconBox{position:relative;z-index:1;background:$white;color:#ED1D22;width:5rem;height:5rem;border-radius:100%;
        padding:10px;
    }   
     }
    .cardText{
        font-family:$font-family;font-size:1.125rem;color:$h2color;text-align:left;
    }
    // .card-deck{
    //     .card{
    //         flex:initial;margin-left:5px;margin-right:5px;
    //         &.MuiGrid-grid-md-4{
    //             max-width: 32.333333%;
    //             flex-basis: 32.333333%;
    //         }
    //     }
    // }
}

@include bp(sm){
    .VideoGlimses-dialogPaper-1{
        width:85%;max-height:19vh;
    }
}



.BannerBox.videoBanner{
    .sixteen-nine:before {  
        background:url(../images/articles.png) center center no-repeat; 
        //background:url(../images/articles.jpg) center center no-repeat;   
        padding-top: 39.25%;
        //padding-top: 23%;
        background-size:cover;  
      }
}

.boxFeedback{
    left:0;right:0;
}