@import 'global';

body{padding-bottom:0rem;}

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: ($height / $width) * 100%;
    }
    > .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }


.NavBar{
//    background: url(../images/wave.png) center center no-repeat;
  background:#e5e9f5;
  top:6rem; 
}

.BannerBox{
    //  display:none;
     h1{font-size:2rem; }  
     p{font-size:1.5rem;
       @include bp(md){
          font-size:1rem;
       }
    
    } 
.sixteen-nine {
    @include aspect-ratio(16, 9);
    margin-top: -18px;
  }
  .sixteen-nine {
    position: relative;
  }
  .sixteen-nine:before {
    display: block;
    content: "";
    background:url(../images/about-banner.jpg) center center no-repeat;
    background-size:cover; 
    width: 100%;
    // padding-top: 56.25%;
    padding-top:40.25%; 
    //padding-top:23%;  
  }
  .sixteen-nine > .content {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    bottom: 0;
    @include bp(sm){
      top:20%;
  }

    h2{font-size:3.3125rem;color:$white;font-weight:600;
      @include bp(md){
        font-size:2rem;  
    }

      @include bp(sm){
          font-size:1.5rem;  
      }
    
    
    }
  }

}

.AboutBox{
    margin-top:5rem;
    @include bp(md){
      margin-top:2rem;
  }
    h2{

      span.blueHead{color:$blue;}
      font-family:$font-family;color:$blue;font-weight:normal
        // span{font-family:$font-family;color:$blue;font-weight:normal;}
        span{font-family:$font-family;color:$blue;font-weight:normal;}    
        @include bp(md){
          font-size:$head-md;
        }
        @include bp(sm){
          font-size:$head-small;
       }

        @include bp(md){
          margin-top:2rem;
      }
    }

    p{
      // font-size:1.375rem;
      font-family:$font-family;
      // margin-top: 2.5rem;margin-bottom:0rem;
      @include bp(md){
        margin-top:1.5rem;
     }

    }

    ul li{
      font-family:$font-family;
    }

    .aboutList{
      .MuiListItemIcon-root{min-width:2rem;}
      @include bp(md){
        .w-50{
          width:100% !important;
        }
      }
      svg{font-size:1rem; fill:#3e46e9;}
      .MuiTypography-displayBlock{
        font-family:$font-family;
      }
     
    }
    .aboutList.trainingList{
      .MuiListItem-root{align-items:inherit;
      }
    }
    .aboutList.trainingList.smalltraining{
      .MuiListItem-root{align-items:inherit;
           @include bp(md){
             display:block;
           }
      }
    }
    .tableAbout{
       white-space: nowrap;
      .MuiTableCell-head{
        font-size:1.25rem;
        @include bp(md){
          font-size:1rem;
        }
      }
      .MuiTableCell-root{
        // font-size:0.975rem;
        font-family:$font-family; 
        font-size:1.25rem;
        @include bp(md){
          font-size:1rem;
        }
      }
    }
}

// 
.MeetTemBox{
    margin-top:5rem;position:relative;
    @include bp(md){
      margin-top:2rem;
      margin-bottom:-1px; 
  }
    h2{font-family:$font-family;text-align:center;color:$blue;margin-bottom:0rem;font-size:$head-size;
      @include bp(md){
        font-size:$head-md;
      }
        @include bp(sm){
          font-size:$head-small;
        }
    }
    &::before{  
        content:"";
        background:url("../images/team.jpg") bottom left no-repeat; 
        position:absolute;
        bottom:0rem;    
        width:100%;
        height:100%;
        background-size:contain;  
    }
}


.team-block{
    position:relative;
    cursor:pointer;

    
}

.team-block .inner-box{
	position:relative;
    padding:40px 70px 28px 0px;
    
}

.team-block .inner-box:before{
	position:absolute;
	content:'';
	right:0px;
	top:0px;
	left:70px;
	bottom:0px;
	border-radius:10px;
	background-color:#f5f5f5;
	-webkit-transition:all 600ms ease;
	-moz-transition:all 600ms ease;
	-ms-transition:all 600ms ease;
	-o-transition:all 600ms ease;
	transition:all 600ms ease;
}

.team-block .inner-box::after{
	position:absolute;
	content:'';
	top:0px;
	left:70px;
	bottom:0px;
	width:0%;
	border-radius:10px;
	-webkit-transition:all 600ms ease;
	-moz-transition:all 600ms ease;
	-ms-transition:all 600ms ease;
	-o-transition:all 600ms ease;
	transition:all 600ms ease;
	background-image: -ms-linear-gradient(top, #0800B5 0%, #F700F1 100%);
	background-image: -moz-linear-gradient(top, #0800B5 0%, #F700F1 100%);
	background-image: -o-linear-gradient(top, #0800B5 0%, #F700F1 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #0800B5), color-stop(100, #F700F1));
	background-image: -webkit-linear-gradient(top, #0800B5 0%, #F700F1 100%);
	background-image: linear-gradient(to bottom, #0800B5 0%, #F700F1 100%);
}

.team-block .inner-box:hover::after{
	width:81%;
}

.team-block .inner-box .social-icons{
	position:absolute;
	right:25px;
	top:100px;
	z-index:1;
}

.team-block .inner-box .social-icons li{
	position:relative;
	margin-bottom:20px;
}

.team-block .inner-box .social-icons li a{
	position:relative;
	color:#e400e1;
	font-size:18px;
	-webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
}

.team-block .inner-box:hover .lower-content h3 a,
.team-block .inner-box:hover .social-icons li a,
.team-block .inner-box:hover .lower-content .designation{
	color:#ffffff;
}

.team-block .inner-box .image{
	position:relative;
	z-index:1;
	border-radius:10px;
	background-color:#ffffff;
	box-shadow:inset 0px 0px 35px rgba(0,0,0,0.20);
}

.team-block .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.team-block .inner-box .lower-content{
	position:relative;
	text-align:left;
	z-index:1;
	padding:30px 15px 0px 110px;
}

.team-block .inner-box .lower-content h3{
	position:relative;
	color:#2200bd;
	font-size:18px;
	font-weight:700;
	line-height:1.3em;
}

.team-block .inner-box .lower-content h3 a{
	position:relative;
	color:#2200bd;
	-webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
}

.team-block .inner-box .lower-content .designation{
	position:relative;
	margin-top:5px;
	color:#777777;
	font-size:14px;
}

.team-block.style-two .inner-box .lower-content h3 a,
.team-block.style-two .inner-box .social-icons li a{
	color:#ff9600;
}

.team-block.style-two .inner-box:hover .lower-content h3 a,
.team-block.style-two .inner-box:hover .social-icons li a{
	color:#ffffff;
}

.team-block.style-two .inner-box::after{
	background-image: -ms-linear-gradient(top, #FFEB3C 0%, #ff9600 100%);
	background-image: -moz-linear-gradient(top, #FFEB3C 0%, #ff9600 100%);
	background-image: -o-linear-gradient(top, #FFEB3C 0%, #ff9600 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #FFEB3C), color-stop(100, #ff9600));
	background-image: -webkit-linear-gradient(top, #FFEB3C 0%, #ff9600 100%);
	background-image: linear-gradient(to bottom, #FFEB3C 0%, #ff9600 100%);
}



// 
.aboutVideobox{
    padding-top:4.9rem; background:#fdf3ff;

    @include bp(sm){
      padding-top:2.5rem;
    }
}

.BestInstructor{
    margin-top:4rem;  position:relative; 
    @include bp(md){
      margin-top:2rem;
    }
    .imgBox{width:70%;margin-left:6rem;
      @include bp(md){
        width:36%;
      }
      @include bp(sm){
        width:70%;
      }
    
    }
    &::before{
        content:"";
        background:url('../images/instructor-bg.png') left top no-repeat;
        // height:1157px;
        height:950px;
        width:100%;
        background-size:contain;
        position:relative;
        left:0rem;top:0rem;z-index:1;
        display: inline-block;
         
        @include bp(md){
          height:163vh;
      }


        @include bp(sm){
            height:90vh;
        }

    }
    .teacherBoxMain{  

         .teacherImage{
          position: absolute;
          z-index: 0;
          width: 100%;
          height: 100%;
          left: 1rem;
          top: 0rem;
          @include bp(md){
            left:0rem
        }
        
         }

         .instructBox{
          position: absolute;
          z-index: 1;
          width: 100%;
          height: 100%;
          right: 1rem;
          top:0rem;
            @include bp(md){
              position:static;
            }
         }
    }
    .InstructorHead{ position:relative;padding-bottom:0.5rem;margin-bottom:2rem;margin-top:6rem;
        @include bp(md){
          margin-top:0rem;
        }
        h3{font-family:$font-family;
          font-size: 2.25rem;
          font-weight: 600;
          @include bp(md){
           font-size:1.5rem;
          }
       }
        &::before{
            content:"";
            position:absolute;
            width:5rem;
            height:2px;background:$blue;bottom:0rem;left:0rem;
        }
        p{font-size:0.975rem;color:$h2color;font-family:$font-family;}
    }   
    .Instructor{
        li{ margin-bottom:2.625rem;background:url("../images/instructorbg.png") left top 5px no-repeat; padding-left:40px;
        background-size:25px;font-size:1.3125rem;font-family:$font-family;color:$h2color;
        @include bp(md){
            margin-bottom:1.5rem;
         }
      }
        }
}

.Activities{
    position:relative;padding-bottom:3rem;
  
    .carousel-indicators {  
        bottom:-5rem;  
        @include bp(sm){
          bottom:-4rem;
        }
      .active{
        background:$blue;
      }  
      li{
        width:15px;height:15px;border-radius:100%;background:#9d9d9d;
      }
    }  
   
    .Blogbox {
      h2{
        color:$blue;font-size:$head-size;
        @include bp(md){
          font-size:$head-md;
        }
        @include bp(sm){
          font-size:$head-small;
        }
      }


      .imgBox{
        .MuiButton-label{
            width:100%;height:220px;
            img{width:100%;height:100%;}
            // @include bp(md){
            //     height:270px;
            // }
        }
    }
    .boxIcon{
        border:3px solid rgba(255, 255, 255, 0.3);position:absolute;border-radius:100%;
    .playIconBox{position:relative;z-index:1;background:$white;color:#ED1D22;width:5rem;height:5rem;border-radius:100%;
        padding:10px;
    } 
  }
      .card{ background:#e5eaf5;padding:1rem; border:1px solid #787878;box-shadow:none;
        .card-body{
          padding:1.25rem 0rem;
        }
      }
    }
}
.FooterAbout{margin-top:-5rem;}

.quoteBox{
  // font-size:1.5rem;
  font-style:italic;  
    font-size: 1.125rem;
    font-weight: 600;  
}


